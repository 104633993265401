

export let _ = require('lodash')
export let axios = require('axios')
export let base64 = require('base-64')
export let $ = require('jquery')
export let moment = require('moment')
// export let API_ENDPOINT = base64.decode(document.querySelector('meta[name="api_endpoint"]').content)

import ('./filters')
import ('./prototypes')