
import { Vue, App, VueRouter } from './bootstrap'

import routes from './routes'
import {_} from './helpers'
const router = new VueRouter(routes)

new Vue({
   render: h => h(App),
   router,
   data() {
      return {
         api: {
            token:null
         }
      }
   },
   methods: {
      async getApiToken() {

         if(!_.get(this.$route, 'params.hash')) return;

         let cliente = await this.$http.get(`cliente/${_.get(this.$route, 'params.hash')}`)
         this.api.token = _.get(cliente,'data.token')

         if(this.api.token) this.$api.defaults.headers.common['Authorization'] = 'Bearer ' + this.api.token
            
      }
   },
   computed: {
      hash() {
         return _.get(this.$route, 'params.hash')
      }
   }
   
   
}).$mount('#app')