<template>
   <fragment>
         <template v-if="_.get(fatura,'id') || hasPreview">
            <!-- <h4 class="mt-4 mt-sm-5"></h4> -->
            <div v-if="!hasPreview && fatura && fatura.unidade" class="card mt-2 border-0 card-beneficiario">
               <div class="card-body">
                  <div class="card-title font-weight-light"><i class="fa fa-university text-primary"></i> Beneficiário</div>
                  <address class="mb-0">
                     <strong>
                        {{fatura.unidade.nome_fantasia}}
                     </strong>
                     <template v-if="fatura.unidade.cnpj">
                        - {{fatura.unidade.cnpj | cnpj-format}}
                     </template>
                     <!-- <template v-if="fatura.unidade.endereco">
                        <br>
                        <div v-html="unidadeEndereco"></div>
                     </template> -->
                  </address>
               </div>
            </div>
            <div v-if="hasPreview" class="card mt-2 border-0 card-beneficiario">
               <div class="card-body">
                  <div class="card-title font-weight-light"><i class="fa fa-university text-primary"></i> Beneficiário</div>
                  <address class="mb-0">
                     <strong>
                        INSTITUIÇÃO TESTE
                     </strong>
                     <template>
                        - 000.00.00.000.0001-1
                     </template>
                     <!-- <template v-if="fatura.unidade.endereco">
                        <br>
                        <div v-html="unidadeEndereco"></div>
                     </template> -->
                  </address>
               </div>
            </div>
            <div v-if="hasPreview || hasPendente" class="alert alert-warning">
               Aguardando pagamento
            </div>
            <div v-if="hasPaga" class="alert alert-success">
               Pagamento já efetuado
            </div>
            <div class="card">
               <div class="card-body">
                  <div class="row">
                     <div class="col-12  col-sm-6">
                        <div class="card-title font-weight-light"><i class="fa fa-heart-o text-primary"></i> Colaborador</div>
                        <fieldset>
                           <legend v-if="hasPreview">FULANO</legend>
                           <legend v-else>{{firstName}}</legend>
                        </fieldset>
                     </div>
                     <div class="col-12 col-sm-6">
                        <div class="card-title font-weight-light"><i class="fa fa-money text-primary"></i> Valor</div>
                        <fieldset>
                           <legend v-if="hasPreview">R$ 10,00</legend>
                           <legend v-else>R$ {{valorTotal | number}}</legend>
                        </fieldset>
                     </div>
                  </div>
               </div>
            </div>
            <div v-if="_.get(boletos,'length',0) > 0" class="card mt-2 card-itens">
               <div class="card-body">
                  <div class="card-title font-weight-light"><i class="fa fa-bars text-primary"></i> Itens</div>
                  <ul class="list-group list-group-flush">
                     <li v-for="(boleto,key) of boletos" :key="key" class="list-group-item">
                        <div class="row">
                           <div class="col">R$ {{boleto.valor_registrado | number}}</div>
                           <div class="col">{{boleto.data_vencimento | moment('DD/MM/YYYY')}}</div>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
            <div v-if="hasDisponivel && hasPendente && !forceDisable && !hasPreview">
               <div v-if="pagamento.metodo_pagamento == 'cartao_credito'" class="mt-4 d-md-block" hidden>
                  <h4 class="text-center"><i class="fa fa-heart text-danger"></i> Automatize sua Doação</h4>
                  <div class="d-flex justify-content-center">
                     <div class="custom-control custom-checkbox">
                     <input v-model="pagamento.cartao.tornar_recorrente" type="checkbox" id="tornarRecorrenteDesktop" class="custom-control-input">
                     <label class="custom-control-label" for="tornarRecorrenteDesktop">Repetir doação no cartão mensalmente.</label>
                  </div>
                  </div>
               </div>
               <div class="p-2 mt-2 base-btn-pagar d-md-block" hidden>
                  <a  @click.prevent="pagar" href v-if="pagamento.metodo_pagamento == 'boleto'" class="btn btn-block btn-lg btn-success">
                     <img src="../../../../assets/icon-ds.svg" width="20em" height="20em" /> Baixar boleto
                  </a>
                  <a  @click.prevent="pagar" href v-else-if="pagamento.metodo_pagamento != 'boleto'" class="btn btn-block btn-lg btn-success">
                     <img src="../../../../assets/icon-ds.svg" width="20em" height="20em" /> Confirmar pagamento
                  </a>
               </div>
               <div class="d-flex d-md-none justify-content-center align-items-center p-4" hidden>
                  <a style="cursor:pointer;" href @click.prevent="scrollTop('#metodo-pagamento')">
                     <i class="fa fa-angle-down fa-2x"></i>
                  </a>
               </div>
            </div>
            <div v-else-if="hasPreview">
               <div class="p-2 mt-2 base-btn-pagar d-md-block" hidden>
               <a  class="btn text-white btn-block btn-lg btn-success">
                  <img src="../../../../assets/icon-ds.svg" width="20em" height="20em" /> Confirmar pagamento
               </a>
               </div>
            </div>
         </template>
   </fragment>
</template>

<script>
   import _ from 'lodash';
   export default {
      props: ['value','pagamento','boletos','pagar','forceDisable','hasPreview'],
      data() {
         return {
            fatura: this.value
         }
      },
      computed: {
         hasDisponivel(){
            return this.hasPendente || this.hasPaga
         },
         hasPaga(){
            return _.indexOf([2,6],Number(_.get(this.fatura,'status.id'))) != -1 || _.get(this.fatura,'status.id') == 5 && _.get(this.boletos,'length') == 0
         },
         hasPendente(){
            return _.get(this.boletos,'length') > 0 && _.indexOf([1,5],Number(_.get(this.fatura,'status.id'))) != -1
         },
         firstName() {
            let nome = _.get(this.fatura,'cadastro.nome_completo','')
            let first = nome ? nome.split(" ")[0] : ''
            return first 
         },
         valorTotal(){

            if(!this.fatura) return 0;
            if(_.get(this.boletos,'length') > 0) return _.sumBy(this.boletos,(boleto) => {
               return Number(boleto.valor_registrado)
            })
            
            return _.get(this.fatura,'valor_documento')

         },
      },
   }
</script>

<style scoped>

</style>