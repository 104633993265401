<template>
   <div>
      <slot name="header"></slot>
      <div class="container-base">
         <slot></slot>
      </div>
   </div>
</template>

<script>
   import 'bootstrap/dist/css/bootstrap.css'
   import 'bootstrap-vue/dist/bootstrap-vue.css'
   export default {
      props:['colorGradienteFrom','colorGradienteTo']
   }
</script>

<style lang="scss" >
   
   @import '~font-awesome/scss/font-awesome';
   // @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
   @import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');
   body{
      // background: linear-gradient(to bottom right, #553980,  #c5367f);
      background: linear-gradient(to bottom right, #144658, #129cce);
      // background: linear-gradient(to bottom right, --color-gradiente-from, --color-gradiente-to);
      background-repeat: no-repeat;
   }
   h1,h2,h3,h4,h5,h6{
      font-family: 'Nunito Sans', serif;
      letter-spacing: -1.3px;
      font-weight: bold;
   }

   
</style>