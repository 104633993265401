
export var Swal = require('sweetalert2')
export function warning(title,text,options={}){
   return Swal.fire({
      type: 'warning',
      title: title,
      text: text,
      ...options
   })
}

export function success(title,text,options={}){
   return Swal.fire({
      type: 'success',
      title: title,
      text: text,
      ...options
   })
}

export function info(title,text,options={}){
   return Swal.fire({
      type: 'info',
      title: title,
      text: text,
      ...options
   })
}

export function danger(title,text,options={}){
   return Swal.fire({
      type: 'error',
      title: title,
      text: text,
      ...options
   })
}

export function loading(title,text){
   Swal.fire({
      title: title || 'Aguarde',
      text: text || 'Processo em Andamento',
      showConfirmButton: false,
      allowOutsideClick:false,
      allowEscapeKey: false,
      allowEnterKey:false,
      buttonsStyling:true
   })
   Swal.showLoading()
}

export function close(){
   Swal.close()
}