<template>
   <div 
      @mouseenter="hover=true" @mouseleave="hover=false" 
      class="card  shadow-sm card-payment-method"
      :class="{'shadow-lg':hover,'bg-info text-white':active,'bg-white  text-dark':!active}"
      @click="$emit('click')"
      >
      <div class="card-body text-center">
         <slot></slot>
      </div>
   </div>
</template>

<script>
   export default {
      props:['active'],
      data() {
         return {
            hover: false
         }
      },
   }
</script>

<style lang="scss" scoped>
   .card-payment-method:hover{
      transform: scale(1.05);
      -webkit-transition: transform .2s; /* Safari prior 6.1 */
      transition: transform .2s;
      cursor:pointer;
   }
</style>