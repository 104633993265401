<template>
   <Layout>
      <div class="container-payment" :style="cssProps">
      <div class="container-fluid">
         <div class="base-payment">
            <div class="row d-flex">
               <template v-if="customSideBar.posicao == 'left'">
                  <div class="col-lg-4 col-md-12 order-last order-md-first bg-white resumo"  :style="`min-height:100vh; ${customSideBar.cor}`">
                     <div class="w-100">
                        <Sidebar v-if="!loadingFatura" v-model="fatura" :pagamento="pagamento" :boletos="boletos" :pagar="pagar" :forceDisable="forceDisable" :hasPreview="hasPreview" />
                     </div>
                  </div>
               </template>
               <div id="metodo-pagamento" class="col-lg-8 col-md-12 text-white d-flex align-items-center metodo-pagamento" :style="`min-height:100vh;${customBackground}`">
                  <div class="w-100">
                     <div class="text-center" v-if="loadingFatura">
                        <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                        <span class="sr-only">Carregando...</span>
                     </div>
                     <template v-else-if="hasPaga">
                        <div v-if="urlLogo" class="d-flex justify-content-center mb-2">
                           <img :src="urlLogo" :width="widthLogo" :height="heightLogo">
                        </div>
                        <div class="text-center">
                           <transition name="bounce">
                              <i v-if="showType2" class="fa fa-check text-success fa-3x mb-2"></i>
                           </transition>
                        </div>
                        <h3 class="text-center mt-4 mt-sm-0" :style="estiloTexto"><vue-typer text='Seu pagamento já foi identificado!' :repeat="0"  @typed='onTyped' ></vue-typer></h3>
                        <!-- <div class="text-center"><vue-typer v-if="showType2" text='Obrigado' :repeat="0" ></vue-typer></div> -->
                     </template>
                     <template v-else-if="_.get(fatura,'id') || hasPreview">
                        <div v-if="urlLogo" class="d-flex justify-content-center mb-2">
                           <img :src="urlLogo" :width="widthLogo" :height="heightLogo">
                        </div>
                        <h3 class="text-center mt-4 mt-sm-0" :style="estiloTexto"><vue-typer :text='fraseIntroducaoUm' :repeat="0"  @typed='onTyped' ></vue-typer></h3>
                        <div class="text-center" :style="estiloTexto"><vue-typer v-if="showType2" :text='fraseIntroducaoDois' :repeat="0" ></vue-typer></div>
                        <transition>
                           <div class="row mt-4" v-if="showMethods">
                              <div class="col-md-4">
                                 <CardPayment @click="pagamento.metodo_pagamento = 'cartao_credito'" :active="pagamento.metodo_pagamento == 'cartao_credito'">
                                    <i class="fa fa-credit-card fa-2x"></i>
                                    <div class="mt-2 method-label">Crédito</div>
                                    <div class="mt-2 method-label-large">Cartão de Crédito</div>
                                 </CardPayment>
                              </div>
                              <div class="col-md-4 mt-2 mt-md-0">
                                 <CardPayment @click="pagamento.metodo_pagamento = 'cartao_debito'"  :active="pagamento.metodo_pagamento == 'cartao_debito'">
                                    <i class="fa fa-credit-card-alt fa-2x"></i>
                                    <div class="mt-2  method-label">Débito</div>
                                    <div class="mt-2 method-label-large">Cartão de Débito</div>
                                 </CardPayment>
                              </div>
                              <div class="col-md-4 mt-2 mt-md-0">
                                 <CardPayment @click="pagamento.metodo_pagamento = 'boleto'"  :active="pagamento.metodo_pagamento == 'boleto'">
                                    <i class="fa fa-barcode fa-2x"></i>
                                    <div class="mt-2 method-label">Boleto</div>
                                    <div class="mt-2 method-label-large">Boleto bancário</div>
                                 </CardPayment>
                              </div>
                           </div>
                        </transition>
                        <transition>
                           <template v-if="pagamento.metodo_pagamento && pagamento.metodo_pagamento != 'boleto'">
                              <div class="card bg-white text-dark mt-4 mb-4 shadow-sm">
                                 <div class="card-body">
                                    <div class="row">
                                       <div class="col-12 col-md-auto col-card-preview">
                                          <div class="card-wrapper"></div>
                                       </div>
                                       <div class="col-12 col-md">
                                          <div class="d-flex d-md-none justify-content-start mb-4" hidden>
                                             <template  v-for="(value,bandeira) of bandeiras">
                                                <img class="mr-2" :src="require(`@/assets/brands/${bandeira}.svg`)" width="30px" >
                                             </template>
                                          </div>
                                          <form id="form-card">
                                             <h5>Dados do cartão de {{ pagamento.metodo_pagamento == 'cartao_credito' ? 'crédito' : "débito" }}</h5>
                                             <div class="row mt-4 data-card">
                                                <div class="col-12 col-md">
                                                   <div class="form-group label-card">
                                                      <div class="d-flex justify-content-start align-items-center">
                                                         <i class="fa fa-credit-card"></i>
                                                         <div class="w-100 ">
                                                            <div class="form-group">
                                                               <FloatingLabel
                                                                  :config="{label: 'Número do Cartão',height:50,labelOffset:{top:5,left:8}, hasError: $v.pagamento.cartao.numero.$error}">
                                                                     <the-mask id="card_number" ref="numeroCartao" autocomplete="off_page" class="w-100" :mask="['#### #### #### ####']" v-model="numeroCartao" type="tel"/>
                                                               </FloatingLabel>
                                                               <small class="ml-2 mt-2 d-block text-danger" v-if="$v.pagamento.cartao.numero.$error && !$v.pagamento.cartao.numero.required">Numero obrigatório</small>
                                                               <small class="ml-2 d-block text-danger" v-if="$v.pagamento.cartao.numero.$error && !$v.pagamento.cartao.numero.cartao">Numero inválido</small>
                                                            </div>
                                                         </div>
                                                      </div>   
                                                   </div>
                                                </div>
                                                <div class="col-12 col-md-5">
                                                   <div class="form-group label-card label-vencimento" style="position:relative;">
                                                      <small class="text-muted" style="top: -8px;left: 8px;position: absolute;">Data de válidade</small>
                                                      <FloatingLabel
                                                               :config="{label: '',height:50,labelOffset:{top:5,left:8},hasError: $v.pagamento.cartao.validade.$error } ">
                                                               <the-mask  ref="dataValidade" id="card_validate"  autocomplete="nope" class="w-100" :mask="['##/##']" placeholder="MM/AA" v-model="$v.pagamento.cartao.validade.$model" type="tel"/>
                                                      </FloatingLabel>
                                                      <small class="ml-2 mt-2 d-block text-danger" v-if="$v.pagamento.cartao.validade.$error && !$v.pagamento.cartao.validade.required">Validade obrigatório</small>
                                                      <small class="ml-2 d-block text-danger" v-if="$v.pagamento.cartao.validade.$error && !$v.pagamento.cartao.validade.anoMes">Validade inválida</small>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="row">
                                                <div class="col-12 col-md">
                                                   <div class="form-group label-card">
                                                      <div class="form-group label-card label-vencimento">
                                                         <FloatingLabel
                                                            :config="{label: 'Nome e Sobrenome',height:50,labelOffset:{top:5,left:8}, hasError: $v.pagamento.cartao.nome_impresso.$error }">
                                                            <input ref="nomeImpresso" id="card_nome" name="[components][content][group_form][controls][card_group][fullname]" autocomplete="cc-name" class="w-100" v-model="$v.pagamento.cartao.nome_impresso.$model" type="text"/>
                                                         </FloatingLabel>
                                                         <small class="ml-2 mt-2 d-block text-danger" v-if="$v.pagamento.cartao.nome_impresso.$error && !$v.pagamento.cartao.nome_impresso.required">Nome e Sobrenome obrigatório</small>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="col-12 col-md-5">
                                                   <FloatingLabel
                                                      :config="{label: 'Código de segurança',height:50,labelOffset:{top:5,left:8}, hasError: $v.pagamento.cartao.cvc.$error}">
                                                      <input  ref="cvc" id="card_cvc"  autocomplete="nope" class="w-100" v-model="$v.pagamento.cartao.cvc.$model" type="tel"/>
                                                   </FloatingLabel>
                                                   <small class="ml-2 mt-2 d-block text-danger" v-if="$v.pagamento.cartao.cvc.$error && (!$v.pagamento.cartao.cvc.required || !$v.pagamento.cartao.cvc.minLength)">CVC obrigatório</small>
                                                </div>
                                             </div>
                                          </form>
                                          <div class="d-md-flex justify-content-start mt-md-2 mt-4" hidden>
                                             <template  v-for="(value,bandeira) of bandeiras">
                                                <img class="mr-2" :src="require(`@/assets/brands/${bandeira}.svg`)" width="30px" >
                                             </template>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </template>
                        </transition>
                        <div v-if="pagamento.metodo_pagamento && hasDisponivel && hasPendente && !forceDisable">
                           <div v-if="pagamento.metodo_pagamento == 'cartao_credito'" class="mt-4 d-block d-md-none" hidden>
                              <h4 class="text-center"><i class="fa fa-heart text-danger"></i> Automatize sua Doação</h4>
                              <div class="d-flex justify-content-center">
                                 <div class="custom-control custom-checkbox">
                                 <input v-model="pagamento.cartao.tornar_recorrente" type="checkbox" id="tornarRecorrenteDesktop" class="custom-control-input">
                                 <label class="custom-control-label" for="tornarRecorrenteDesktop">Repetir doação no cartão mensalmente.</label>
                              </div>
                              </div>
                           </div>
                          <div class="base-btn-pagar d-block d-md-none mt-3 mb-3" hidden>
                              <a @click.prevent="pagar" href v-if="pagamento.metodo_pagamento == 'boleto'" class="btn btn-lg btn-success">
                                 <img src="../../assets/icon-ds.svg" width="20em" height="20em" /> Baixar boleto
                              </a>
                              <a @click.prevent="pagar" href v-else-if="pagamento.metodo_pagamento != 'boleto'" class="btn btn-lg btn-success">
                                 <img src="../../assets/icon-ds.svg" width="20em" height="20em" /> Confirmar pagamento
                              </a>
                           </div>
                        </div>
                     </template>
                  </div>
               </div>
               <template v-if="customSideBar.posicao == 'right'">
                  <div class="col-lg-4 col-md-12 order-first order-md-last bg-white resumo"  :style="`min-height:100vh; ${customSideBar.cor}`">
                     <div class="w-100">
                        <Sidebar v-if="!loadingFatura" v-model="fatura" :pagamento="pagamento" :boletos="boletos" :pagar="pagar" :forceDisable="forceDisable" :hasPreview="hasPreview" />
                     </div>
                  </div>
               </template>
            </div>
         </div>
      </div>
      </div>
   </Layout>
</template>
<script>
   
   import _ from 'lodash';
   import {moment,$} from '$helpers';
   import {warning,success,info,danger,loading,close} from '$helpers/alerts';
   import Layout from '$src/layouts/Default.vue';
   import CardPayment from './payment/CardPayment.vue';
   import { VueTyper } from 'vue-typer'
   import { required, minLength, between } from 'vuelidate/lib/validators'
   // import FloatLabel from 'vue-float-label/components/FloatLabel'
   import {TheMask} from 'vue-the-mask'
   import FloatingLabel from 'vue-simple-floating-labels'
   import Sidebar from './layout/default/Sidebar';

   const Card = require('card')
   const strtr = require('locutus/php/strings/strtr')

   export default {
      data() {
         return {
            showMethods: false,
            fatura:null,
            boletos:null,
            loadingFatura: false,
            showType2:false,
            passo:1,
            pagamento:{
               metodo_pagamento:null,
               cartao:{
                  numero:null,
                  validade: null,
                  nome_impresso:null,
                  cvc:null,
                  tornar_recorrente:null
               }
               // cartao:{
               //    numero:'5397594343115947',
               //    validade: '10/23',
               //    nome_impresso:'RODRIG B RODRIGUES',
               //    cvc:123
               // }
            },
            forceDisable:false,
            page:null
         }
      },
      watch: {
         'pagamento.metodo_pagamento'(newValue, oldValue) {
            
            this.$v.pagamento.cartao.numero.$model = null
            this.$v.pagamento.cartao.validade.$model = null
            this.$v.pagamento.cartao.nome_impresso.$model = null
            this.$v.pagamento.cartao.cvc.$model = null

            this.$v.$reset()

            if(newValue && newValue != 'boleto'){
               this.$nextTick(() => {
                  if(this.$refs.numeroCartao) this.$refs.numeroCartao.$el.focus()

                  let card = new Card({
                     container: '.card-wrapper',
                     form: '#form-card',
                     placeholders: {
                        number: '•••• •••• •••• ••••',
                        name: 'João Paulo',
                        expiry: '**/****',
                        cvc: '••••'
                     },
                     masks: {
                        cardNumber: '•', // optional - mask card number,
                        cardCvc: '•'
                     },
                     formSelectors:{
                        numberInput: 'input#card_number', // optional — default input[name="number"]
                        expiryInput: 'input#card_validate', // optional — default input[name="expiry"]
                        cvcInput: 'input#card_cvc', // optional — default input[name="cvc"]
                        nameInput: 'input#card_nome' // optional - defaults input[name="name"]
                     },
                     formatting:true
                  });

               })
            }

         }
      },
      validations: {
         pagamento: {
            cartao: {
               numero:{
                  required,
                  cartao(numero){
                     return _.get(_.filter(this.bandeiras,(bandeira) => bandeira.test(numero)),'length',0) > 0;
                  }
               },
               validade:{
                  required,
                  anoMes(value){
                     let anoMes = moment(value,"MMYY")
                     let VALID =  anoMes.isValid() && anoMes.format('YYYY-MM') >= moment().format('YYYY-MM')
                     return VALID
                  }
               },
               nome_impresso:{
                  required
               },
               cvc:{
                  required,
                  minLength: minLength(3)
               }
            }
         }
         //, validationGroup: ['pagamento.cartao.numero']
      },

      methods: {

         async pagar(){

            if(!this.hasPagar) return;

            if(this.pagamento.metodo_pagamento == 'boleto'){
               
               await this.gerarBoletos()
               
            }else if (this.pagamento.metodo_pagamento){
               
               await this.pagarComCartao()

            }

         },

         async gerarBoletos(){

            loading("Gerando boletos...") 

            let event = await this.$api.post('boletos/pdf',{
               cadastro_id:this.fatura.cadastro.id,
               ids:_.map(this.boletos,boleto => boleto.id),
               template: _.get(this.boletos,'length',0) > 1 ? 'carne-a4' : 'avulso'
            },{ responseType: 'blob' }).catch(() => {
               warning("Houve alguma divergência","Não foi possivel gerar o boleto no momento")
               return;
            })

            close()

            let blob = new Blob([event.data], {
               filename: 'boleto',
               type: 'application/pdf'
            });


               // console.log('teste:' + this.$browserDetect.meta.name);
            if (this.$browserDetect.meta.name == 'Opera' || this.$browserDetect.meta.name == 'Safari') { //Safari & Opera iOS
               // var url = $window.URL.createObjectURL(blob);
               // window.location.href = url;
               // let a = window.document.createElement("a");
               // a.target = "_blank";
               // a.href = URL.createObjectURL(blob, {type: "application/pdf"});
               // a.download = "boleto.pdf";
               // document.body.appendChild(a);
               // a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
               // document.body.removeChild(a);
               var url = window.URL.createObjectURL(blob);
               console.log('teste:');
               console.log(url);
               window.location.href = url;

            } else if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
               window.navigator.msSaveBlob(blob, "boleto.pdf");
            else
            {
               let a = window.document.createElement("a");
               a.target = "_blank";
               a.href = window.URL.createObjectURL(blob, {type: "application/pdf"});
               a.download = "boleto.pdf";
               document.body.appendChild(a);
               a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
               document.body.removeChild(a);
            };

         },

         getBandeira(){

            if(!this.pagamento.cartao.numero) return;

            let bandeira = _.omitBy(this.bandeiras,bandeira => !bandeira.test(this.pagamento.cartao.numero))

            if(bandeira){
                return Object.keys(bandeira)[0]
            }

            return false;

         },

         async pagarComCartao(){

            this.$v.pagamento.cartao.$touch()

            if(this.$v.pagamento.cartao.$invalid) return warning("Verifique os dados do cartão")

            let dataRequest = {
               boletos: _.map(this.boletos,(boleto) => {
                  return {
                     id: boleto.id,
                     valor_registrado: boleto.valor_registrado
                  }
               }),
               canal_id: 16,
               protocolo_retorno: process.env.NODE_ENV == 'development' ? 'http' : 'https',
               recorrencia:null
            }

            if(!this.getBandeira())  return warning("Bandeira não disponível")

            let dataCartao = {
               nome_impresso : this.pagamento.cartao.nome_impresso,
               bandeira : this.getBandeira(),
               numero : this.pagamento.cartao.numero,
               mes_validade : moment(this.pagamento.cartao.validade,"MM/YY").format("MM"),
               ano_validade : moment(this.pagamento.cartao.validade,"MM/YY").format("YYYY"),
               codigo_seguranca : this.pagamento.cartao.cvc,
               tipo_retorno: null
            }


            if(this.pagamento.metodo_pagamento == "cartao_credito"){
               dataRequest.cartao_de_credito = dataCartao

               if(this.pagamento.cartao.tornar_recorrente){

                  dataRequest.recorrencia = {
                     ciclo_periodo: 1,
                     ciclo_tipo: 'meses'
                  }

               }

            }else if (this.pagamento.metodo_pagamento == "cartao_debito"){
               dataRequest.cartao_de_debito = dataCartao
               dataRequest.tipo_retorno = "redirect"
               dataRequest.url_retorno = `${process.env.VUE_APP_BASE_URL}/debito/retorno`
               
            }else{

               warning("Houve alguma divergência","Metodo de pagamento não disponível");
               return;

            }

            loading("Processando pagamento","Por favor aguarde")
            try{

               let pagar = await this.$api.post(`/cartoes/${this.fatura.id}/boletos`,dataRequest)
               if(pagar.data.status != 2) return warning("Não aprovado","O pagamento não foi autorizado")

               if(this.pagamento.metodo_pagamento == 'cartao_debito'){

                  let popup = window.open(pagar.data.authenticationUrl,'popup');
                  

                  if(!popup){
                     
                     let watch = await warning("Ops..","Seu navegador não permitiu continuarmos. Para iniciar o pagamento habilite o pop-up",{
                        // showConfirmButton: false,
                        // html: `
                        //    <a href="javascript:void(window.open('${pagar.data.authenticationUrl}','nova','width=800,height=600'))" class="btn btn-info">Abrir BankLine</a>
                        // `
                     })
                     
                  }
                  
                  let t = this;

                  window.addEventListener('message',function(e){
                     
                     if(_.get(e.data,'type') == 'response_debito'){

                        popup.close();

                        let data = e.data;

                        if(data.status == 'ok'){
                           success("Pagamento autorizado","Obrigado");
                        }else{
                           
                           t.$v.pagamento.cartao.numero.$model = null
                           t.$v.pagamento.cartao.validade.$model = null
                           t.$v.pagamento.cartao.nome_impresso.$model = null
                           t.$v.pagamento.cartao.cvc.$model = null
                        
                           t.$v.$reset()
                           
                           danger(data.message ? data.message : "Pagamento não autorizado","Seu pagamento parece que não foi autorizado.");
                        }

                        t.fetchFatura()

                     }

                  },false);


               }else{
                  success("Pagamento aprovado","Se pagamento foi aprovado com sucesso")
                  this.fetchFatura()
               }


            } catch(e){
               console.log(e)
               warning("Houve alguma divergência","Tente novamente")
               this.fetchFatura()
            }
            

         },

         scrollTop(element){

            var el = $(element)
            var body = $("html, body")
            body.stop().animate({scrollTop:el.offset().top}, 500, 'swing');

         },

         onTyped(){
            let t = this
            setTimeout(() => {
               t.showMethods = true
               t.showType2=true
            },300)
         },
         
         async fetchFatura() {

            if(!this.$root.hash) return;

            this.loadingFatura = true
            
            let oFatura = await this.$http.get(`/pagamentos/${this.$root.hash}`).catch((e) => {
               this.loadingFatura = false
               warning("Houve alguma divergência","Tente novamente mais tarde",{
                  showConfirmButton:false,
                  backdrop:true,
                  allowOutsideClick:false
               })
               console.error(e);
            })

            let fatura = await this.$api.get(`pagamentos/${oFatura.data.pagamento}`).catch((e) => {
               this.loadingFatura = false
               warning("Houve alguma divergência","Tente novamente mais tarde",{
                  showConfirmButton:false,
                  backdrop:true,
                  allowOutsideClick:false
               })
               console.error(e);
            })
            this.fatura = fatura.data;
            
            if(oFatura.data.boletos || this.fatura.tipo.id == 4){

               await this.fetchBoletos(oFatura.data.boletos).catch((e) => {
                     this.loadingFatura = false
                     this.forceDisable = true
                     warning("Houve alguma divergência","Tente novamente mais tarde",{
                     showConfirmButton:false,
                     backdrop:true,
                     allowOutsideClick:false
                  })
                  console.error(e);
               })

            }

            this.loadingFatura = false
            
         },

         async fetchBoletos(ids){


            let boletos = await this.$api.get(`boletos`,{
               params: {
                  pagamento_id:this.fatura.id,
                  paginacao:'nao'
               }
            })


            if(_.get(boletos.data,'itens.length',0) == 0){
               throw "Boletos não encontrados";
            }

            if(!ids) return this.boletos = _.filter(boletos.data.itens,(b) => {
               let sBoleto = _.indexOf([2],Number(b.status.id)) != -1 && b.data_vencimento_registro > moment().format("YYYY-MM-DD")
               return sBoleto
            } )

            this.boletos = _.filter(boletos.data.itens,(b) => {
               let aIds = _.map(ids,(i) => String(i))
               let sBoleto = _.indexOf([2],Number(b.status.id)) != -1 && _.indexOf(aIds,String(b.id)) != -1 && ( b.data_limite_pagamento >= moment().format("YYYY-MM-DD") ||  b.data_vencimento_registro >= moment().format("YYYY-MM-DD"))
               
               return sBoleto
            } )
            if(!this.boletos) throw "Nenhum boleto disponível";

         },

         async customPage(){

            // let oCliente = await this.$http.get(`/cliente/${this.$root.hash}/config`)
            // this.page = oCliente.data
            
            if(this.hasPreview){
               let c = strtr(this.$route.params.config,'._-','+/=')
               var b = new Buffer(c, 'base64')
               let page = JSON.parse(b.toString())
               this.page = _.get(page,'langs.ptbr')
               return;
            }

            let page = await this.$api.get(`paginas`,{
               params:{
                  tipo :'checkout',
                  ativo: 'sim'
               }
            }).catch(() => {})

            if(!page) return;

            this.page = _.get(page,'data.itens[0].config.langs.ptbr')

         }

      },

      computed: {
         customSideBar(){

            let posicao = _.get(this.page,'sidebar.posicao','right')
            let color = _.get(this.page,'sidebar.cor')

            if(color){
               color = `background-color: ${color} !important;`
            }

            return {
               posicao: posicao,
               cor: color
            }
         },
         backgroundPositionStyle(){
            let posicao = _.get(this.page,'posicao','right')

            if(posicao == 'left') return `linear-gradient(-90deg, white  33.333333%,transparent 0)`
            return `linear-gradient(transparent 0,-90deg, white  33.333333%)`
            
         },
         customBackground(){
            let background = _.get(this.page,'url_background')
            if(background){
               return `background:url(${background});background-size:cover;`
            }
         },
         // customBackgroundColorRight(){
         //    let color_right = _.get(this.page,'color_right')
         //    if(color_right){
         //       return `background-color: ${color_right} !important;`
         //    }
         // },
         urlLogo(){
            let url_logo = _.get(this.page,'logo.url')
            return url_logo
         },
         widthLogo(){
            let url_logo = _.get(this.page,'logo.width')
            return url_logo
         },
         heightLogo(){
            let url_logo = _.get(this.page,'logo.height')
            return url_logo
         },
         fraseIntroducaoUm(){
            return _.get(this.page,'frases.introducao.um','Como você deseja pagar?')
         },
         fraseIntroducaoDois(){
            return _.get(this.page,'frases.introducao.dois','Escolha uma das opções abaixo')
         },
         estiloTexto(){

            
         },
         bandeiras(){

            const baideira_cred = {
               visa      : /^4[0-9]{12}(?:[0-9]{3})/,
               mastercard : /^5[1-5][0-9]{14}/,
               diners    : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
               amex      : /^3[47][0-9]{13}/,
               discover  : /^6(?:011|5[0-9]{2})[0-9]{12}/,
               // hipercard  : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
               elo        : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
               jcb        : /^(?:2131|1800|35\d{3})\d{11}/,       
               aura      : /^(50\d{2})(\d{8})(\d{4})$/     
            };
            
            const baideira_deb = {
               visa      : /^4[0-9]{12}(?:[0-9]{3})/,
               mastercard : /^5[1-5][0-9]{14}/    
            };

            let bandeiras = baideira_cred;
            if(this.pagamento.metodo_pagamento == 'cartao_debito') bandeiras = baideira_deb

            return bandeiras

         },
         firstName() {
            let nome = _.get(this.fatura,'cadastro.nome_completo','')
            let first = nome ? nome.split(" ")[0] : ''
            return first 
         },
         valorTotal(){

            if(!this.fatura) return 0;
            if(_.get(this.boletos,'length') > 0) return _.sumBy(this.boletos,(boleto) => {
               return Number(boleto.valor_registrado)
            })
            
            return _.get(this.fatura,'valor_documento')

         },
         unidadeEndereco(){

            const oEnd = this.fatura.unidade.endereco

            let endereco = `${oEnd.logradouro}${ oEnd.numero ? ', ' + oEnd.numero : ''}`
            let cidade = `${oEnd.cidade ? '<br>' : ''}${oEnd.cidade}${oEnd.uf ? ', ' : ''}${oEnd.uf}`

            return `${endereco}${cidade}`

         },
         hasPreview(){
            return this.$root.hash == 'preview'
         },
         hasPaga(){
            return _.indexOf([2,6],Number(_.get(this.fatura,'status.id'))) != -1 || _.get(this.fatura,'status.id') == 5 && _.get(this.boletos,'length') == 0
         },
         hasPendente(){
            return _.get(this.boletos,'length') > 0 && _.indexOf([1,5],Number(_.get(this.fatura,'status.id'))) != -1
         },
         hasDisponivel(){
            return this.hasPendente || this.hasPaga
         },
         hasPagar(){

            if(this.pagamento.metodo_pagamento == 'boleto') return true;

            this.$v.pagamento.cartao.$touch()
            if(this.$v.pagamento.cartao.$invalid){

               if(this.$v.pagamento.cartao.numero.$invalid) this.$refs.numeroCartao.$el.focus()
               else if(this.$v.pagamento.cartao.validade.$invalid) this.$refs.dataValidade.$el.focus()
               else if(this.$v.pagamento.cartao.nome_impresso.$invalid) this.$refs.nomeImpresso.focus()
               else if(this.$v.pagamento.cartao.cvc.$invalid) this.$refs.cvc.focus()
               
            }

            return !this.$v.pagamento.cartao.$invalid

         },
         numeroCartao:{
            get(){
               return this.$v.pagamento.cartao.numero.$model
            },
            set(value){
               this.$v.pagamento.cartao.numero.$model = _.replace(value,/[^0-9]+/g,'')
            }
         },
          cssProps() { 
            let cor =  _.get(this.page,'frases.cor','')
            return {'--color-text': cor}
          }
      },

      async created () {

         if(this.hasPreview){
            await this.customPage()
            return;
         }

         await this.$root.getApiToken()
         await this.customPage()
         await this.fetchFatura()

         
         if(!this.hasDisponivel){
            info('Fatura indisponível no momento',"Esta fatura não está disponivel para pagamento no momento",{
               showConfirmButton:false,
               backdrop:true,
               allowOutsideClick:false
            })
            return;
         }

      },

      components: {
         Layout,CardPayment,VueTyper,TheMask,FloatingLabel, Sidebar
      }
   }
</script>
<style lang="scss">

   .jp-card-container{
      transform: scale(0.75) !important;  
   }
   
   .vfl-label {
      text-transform: uppercase;
   }
   
   .vfl-label-on-input {
      top: -1.3em;
      text-transform: inherit;
   }
   .label-vencimento .vfl-label{
      display: block !important;
      opacity: 1;
      text-transform: inherit;
      top: -1.3em;
   }
   
   
   .vfl-label-on-focus {
      color: var(--info);
   }
   
   .vfl-label + input {
      padding-left: 0;
      font-size: 100%;
      border: 0;
      border-bottom: 1px solid rgba(0,0,0,.25);
      transition: border 0.2s;
      background: transparent;
   }
   
   .vfl-label-on-focus + input  {
      border-bottom: 1px solid var(--info);
   }

   .label-card label{
      color: rgba(3, 23, 40, 0.5) !important;
   }


</style>

<style lang="scss" scoped>

   @import "~bootstrap/scss/functions";
   @import "~bootstrap/scss/variables";
   @import "~bootstrap/scss/mixins/_breakpoints";

   .base-payment{
      min-height: 100vh;
   }

   .card-itens{
      max-height:50vh;overflow:auto;
   }

   @include media-breakpoint-down(md){
      .col-card-preview{
         padding: 0px !important;
      }
      .resumo{
         min-height: inherit !important;
      }
   }

   @include media-breakpoint-up(lg) {
      .container-payment{
         background: --background-position-style;
      }
      // .card-beneficiario{
      //    max-height:22vh;overflow:auto;
      // }
      .card-itens{
         max-height:22vh;overflow:auto;
      }
   }

   .method-label{ display:block;}
   .method-label-large{ display:none;}

   @include media-breakpoint-up(lg) {
      .method-label-large{ display:block;}
      .method-label{ display:none;}
   }

   @include media-breakpoint-down(sm) {
      .method-label-large{ display:block;}
      .method-label{ display:none;}
   }

   .base-btn-pagar{
      text-align: left;
   }
   @include media-breakpoint-down(lg){
      .base-btn-pagar{
         text-align: center;
      }
   }

   .fade-enter-active, .fade-leave-active {
      transition: opacity .8s;
   }
   .fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
      opacity: 0;
   }

   .bounce-enter-active {
      animation: bounce-in .5s;
   }
   .bounce-leave-active {
      animation: bounce-in .5s reverse;
   }
   @keyframes bounce-in {
      0% {
         transform: scale(0);
      }
      50% {
         transform: scale(1.5);
      }
      100% {
         transform: scale(1);
      }
   }

</style>

<style lang="scss" >

   .vue-typer {
  /* Styles for the vue-typer container
     e.g. font-family, font-size  */
 
   .custom.char {
      color:var(--color-text);
   }
 
   .custom.caret {
      background-color:white;
   }

  }
</style>