
import Home from '../components/views/Home.vue';
import Payment from '../components/views/Payment.vue';

export default {
   mode: 'history',
   routes: [
      {
         name: 'home',
         path: '',
         component: Home,
         meta: {
            title: 'Home',
         },
      },
      {
         name: 'payment',
         path: '/payment/:hash/:config',
         component: Payment,
         meta: {
            title: 'Checkout',
         },
      },
      {
         name: 'payment',
         path: '/payment/:hash',
         component: Payment,
         meta: {
            title: 'Checkout',
         },
      }
   ]
}

