import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Fragment from 'vue-fragment'

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

import VuePostmessage from 'vue-postmessage'

Vue.config.productionTip = false

import {moment,_,axios} from './helpers';

Vue.use(VuePostmessage)
Vue.use(VueRouter)
Vue.use(Fragment.Plugin)

Vue.prototype.$http = axios.create({
   baseURL: process.env.VUE_APP_BASE_URL || '/api/',
   headers: {
       'X-Requested-With': 'XMLHttpRequest',
       'Content-Type': 'application/json'
   }
});

Vue.prototype.$api = axios.create({
   baseURL: process.env.VUE_APP_BASE_API,
   headers: {
       'X-Requested-With': 'XMLHttpRequest',
       'Content-Type': 'application/json'
   }
})

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.mixin({
   computed: {
       _() {
           return _;
       },
   },
   methods: {
       $(rootObject, nodes, defaultNode) {

           let _defaultNode = defaultNode != undefined ? defaultNode : null
           return _.get(rootObject, nodes, _defaultNode)
       },
       replaceQuery(oQuery) {
           let Query = Object.assign({}, this.$route.query);
           let query = _.merge(Query, oQuery)
           let obj = _.omitBy(query, (m) => {
               return !m
           })
           return this.$router.replace({
               query: obj
           })
       },
       toNumber(value){
           return Number(value)
       },
       moment() {
           return moment.apply(this, arguments);
       },
       getRoute() {
           return this.getRouter(this.$route.name)
       },
       getRouter(name) {
           return _.find(this.$root.getAuthRoutes(), (r) => r.name == name)
       },
       focusByCheckbox(e, ref) {
           if (e.target.checked == true) {
               this.$nextTick(() => {
                   this.$refs[ref].focus();
               });
           }
       },
       number(value, decimal){
           decimal = !decimal && decimal !== 0 ? 2 : decimal
           if(decimal == 0)
               return Number(value).toFixed(0)
           return Number(Number(value).toFixed(decimal)).toLocaleString('pt-br', {minimumFractionDigits: 2})
       },
       documentFormat(documento){

           documento = _.replace(documento,/[^0-9]/,'');

           let oDoc = {
              formated: documento,
              number: documento,
              type:null
           };

           switch(_.get(documento,'length')){
              case 8:
                    oDoc.type = 'RG';
                    oDoc.formated = _.replace(documento,/([0-9]{2})([0-9]{3})([0-9]{3})/,'$1.$2.$3');
                 break;
              case 9:
                    oDoc.type = 'RG';
                    oDoc.formated = _.replace(documento,/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{1})/,'$1.$2.$3-$4');
                 break;
              case 11:
              case 10:

                    oDoc.type = 'CPF';
                    oDoc.formated = _.replace(documento,/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{1,2})/,'$1.$2.$3-$4');

                 break;
              case 14:
                 
                    oDoc.type = 'CNPJ';
                    oDoc.formated = _.replace(documento,/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/,'$1.$2.$3/$4-$5');

                 break;
           }

           return oDoc

       }
   }
})


export {Vue,VueRouter,App}