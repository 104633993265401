<template>
   <div>
      @payment {{moment()}}
   </div>
</template>

<script>
   export default {
      
   }
</script>

<style lang="scss" scoped>

</style>